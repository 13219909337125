
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './DashboardLayout.css'; // Füge eine eigene CSS-Datei für zusätzliches Styling hinzu
import { Container, Row, Col, Card, ProgressBar } from 'react-bootstrap';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Dot,
  } from 'recharts';
import AuftragsListeElement from './auftragsliste_element';
import Navbar from './navbar';
import TaskElement from './task_element';
import useAdminCheck from './rechte_admin';




const DashboardLayout = () => {

  useAdminCheck();
    


  const profil = localStorage.getItem("Role");


  const vollname = localStorage.getItem("Vollname");
  const encodedVollname = encodeURIComponent(vollname); 


    // Dummy-Daten für die Diagramme
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    const [tasks, setTasks] = useState([]);

    const [auftragsAnzahl, setAuftragsAnzahl] = useState(0); // State für die Anzahl der Aufträge
    const [currentMonthSales, setCurrentMonthSales] = useState(0); // Umsatz für den aktuellen Monat
    const [currentMonth, setCurrentMonth] = useState(0); // Umsatz für den aktuellen Monat
    const [AnnualProfit, setAnnualProfit] = useState(0); // Jahresumsatz

    const [annualSales, setAnnualSales] = useState(0); // Jahresumsatz
    const [showG, setShowG]=useState(false);

    
    useEffect(() => {
      const checkAdminRole = () => {
        const profil = parseInt(localStorage.getItem("Role"));
        if (profil === 20) {
          setShowG(true);
        }
      };
  
      checkAdminRole();
    }, []);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const response = await fetch('http://34.240.112.160:80/tasks');
                const data = await response.json();
                setTasks(data);
            } catch (error) {
                console.error('Error fetching tasks:', error);
            }
        };

        fetchTasks();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
          try {




            const response = await fetch('http://34.240.112.160:80/bud');
            const salesData = await response.json();
    
            // Filter data for the current year
            const currentYear = new Date().getFullYear();
            const filteredData = salesData
              .filter((entry) => entry.year === currentYear)
              .map((entry) => ({
                month: entry.month, // Convert month to string for consistency with dummy data
                sales: parseFloat(entry.totalUmsatz.replace(',', '')), // Assuming totalUmsatz is a string representing a number
                costs: parseFloat(entry.totalKosten.replace(',', '')), // Assuming totalKosten is a string representing a number
              }));
    
          // setData1(filteredData);
              console.log('dieses Jahr', currentYear)






              
            const previousYear = currentYear -1 ;
            const previousYearData = salesData
              .filter((entry) => entry.year === previousYear)
              .map((entry) => ({
                month: entry.month,
                sales: parseFloat(entry.totalUmsatz.replace(',', '')),
                costs: parseFloat(entry.totalKosten.replace(',', '')), // Assuming totalKosten is a string representing a number

              }));

              const previousYearDataCost = salesData
              .filter((entry) => entry.year === previousYear)
              .map((entry) => ({
                month: entry.month,
                sales: parseFloat(entry.totalUmsatz.replace(',', '')),
                costs: parseFloat(entry.totalKosten.replace(',', '')), // Assuming totalKosten is a string representing a number

              }));
                console.log(previousYearDataCost)




            const yearBeforePrevious = previousYear + 2;
            const yearBeforePreviousData = salesData
              .filter((entry) => entry.year === yearBeforePrevious)
              .map((entry) => ({
                month: entry.month,
                sales: parseFloat(entry.totalUmsatz.replace(',', '')),
              }));
    
            // Define an array of month abbreviations
            const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
    
            // Fetch data for the three years for data2
            setData2(
              monthAbbreviations.map((monthAbbreviation, index) => {
                const lastYearEntry = previousYearData.find((entry) => entry.month === index + 1);
                const currentYearEntry = filteredData.find((entry) => entry.month === index + 1);
                const previousYearEntry = yearBeforePreviousData.find((entry) => entry.month === index + 1);
    
                return {
                  month: monthAbbreviation,
                  lastYear: lastYearEntry ? lastYearEntry.sales : 0,
                  currentYear: currentYearEntry ? currentYearEntry.sales : 0,
                  previousYear: previousYearEntry ? previousYearEntry.sales : 0,
                };
              })
            );

        // Umsatz für den aktuellen Monat berechnen
        const currentMonth = new Date().getMonth() + 1;
        const currentMonthData = filteredData.find((entry) => entry.month === currentMonth);
        if (currentMonthData) {
          setCurrentMonth(currentMonthData.sales.toFixed(0)); 
        }        
            
            
        // Jahresumsatz berechnen
        const annualSalesSum = filteredData.reduce((sum, entry) => sum + entry.sales, 0);
        setAnnualProfit(annualSalesSum.toFixed(0)); // Jahresumsatz ohne Nachkommastellen
     

        

        
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []); 


      useEffect(() => {
        const fetchData = async () => {
          try {


            const response = await fetch('http://34.240.112.160:80/SalesData');
            const salesData = await response.json();
    
            // Filter data for the current year
            const currentYear = new Date().getFullYear();
            const filteredData = salesData
              .filter((entry) => entry.year === currentYear)
              .map((entry) => ({
                month: entry.month, // Convert month to string for consistency with dummy data
                sales: parseFloat(entry.totalUmsatz.replace(',', '')), // Assuming totalUmsatz is a string representing a number
                costs: parseFloat(entry.totalKosten.replace(',', '')), // Assuming totalKosten is a string representing a number
              }));
    
           setData1(filteredData);
              console.log('dieses Jahr', currentYear)

        // Umsatz für den aktuellen Monat berechnen
        const currentMonth = new Date().getMonth() + 1;
        const currentMonthData = filteredData.find((entry) => entry.month === currentMonth);
        if (currentMonthData) {
          setCurrentMonthSales(currentMonthData.sales.toFixed(0)); 
        }        
        
        // Jahresumsatz berechnen
        const annualSalesSum = filteredData.reduce((sum, entry) => sum + entry.sales, 0);
        setAnnualSales(annualSalesSum.toFixed(0)); // Jahresumsatz ohne Nachkommastellen
     

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []); 

    
    


// Funktion zum Zählen der Aufträge
useEffect(() => {
  const countAuftrage = async () => {
    try {
      const response = await fetch('http://34.240.112.160:80/auftrag');
      const auftrageData = await response.json();
      const currentYear = new Date().getFullYear();
      const auftrageCurrentYear = auftrageData.filter((auftrag) => {
        const auftragYear = new Date(auftrag.auftrag_datum).getFullYear();
        return auftragYear === currentYear;
      });
      setAuftragsAnzahl(auftrageCurrentYear.length);
    } catch (error) {
      console.error('Error counting Aufträge:', error);
    }
  };
  countAuftrage();
}, []); // 




      const CustomDot = (props) => {
        const { cx, cy, payload, value } = props;
        return (
          <Dot
            cx={cx}
            cy={cy}
            r={0} // Setze den Radius auf 0, um den Punkt zu verbergen
            fill="#042940" // Dunkles Blau
            stroke="#fff"
            strokeWidth={2}
            onMouseOver={() => console.log(`Wert: ${value}`)} // Hier können Sie Ihre eigene Aktion beim Mouseover hinzufügen
          />
        );
      };
    
      const CustomYAxisTick = (props) => {
        const { x, y, payload } = props;
        return <text x={x} y={y} dy={-10} textAnchor="end" fill="#D6D58E">{`${payload.value / 1000}k`}</text>;
      };
  return (
    <div>
    
    <Container fluid className="dashboard-container">
            <Row className="header-row">
        <Col>
          <h1>Hallo {vollname}!</h1>
        </Col>
      </Row>
   


      {showG && (
      <Row className="info-boxes-row">
        
        {/* 6 kleine Boxen */}
        <Col md={2}>
    
          <Card className="info-box">
            <Card.Body>
                            <Card.Title className="info-box-number">{auftragsAnzahl}</Card.Title>
              <Card.Text className="info-box-legend">Aufträge des Jahres</Card.Text>
            </Card.Body>
          </Card>
        
          </Col>
        <Col md={2}>
          <Card className="info-box">
          <Card.Body>
              <Card.Title className="info-box-number">{currentMonthSales} EUR</Card.Title>
              <Card.Text className="info-box-legend">Geplanter Monatsumsatz</Card.Text>
            </Card.Body>
           
          </Card>
        </Col>
        <Col md={2}>
          <Card className="info-box">
          <Card.Body>             
              <Card.Title className="info-box-number">{currentMonth} EUR</Card.Title>
              <Card.Text className="info-box-legend">Rohertrag  Monat</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={2}>
          <Card className="info-box">
          <Card.Body>
              <Card.Title className="info-box-number">{annualSales} EUR</Card.Title>
              <Card.Text className="info-box-legend">Erreichter Jahresumsatz</Card.Text>
            </Card.Body>

          </Card>
        </Col>
        <Col md={2}>
          <Card className="info-box">
          <Card.Body>             
              <Card.Title className="info-box-number">{AnnualProfit} EUR</Card.Title>
              <Card.Text className="info-box-legend">Rohertrag Jahr</Card.Text>
            </Card.Body>
          </Card>
        </Col>
        {/* Wiederhole dieses Block für die restlichen Boxen */}
      </Row>
                )}
        
        {showG && (
     
      <Row className="graph-row">
        {/* 2 Container für Graphen */}
 
        <Col md={6}>
        <Card className="info-box">
            <Card.Body>
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data1} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <defs>
                <linearGradient id="salesGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#005C53" stopOpacity={0.8} /> {/* Dunkles Blau */}
                  <stop offset="100%" stopColor="#005C53" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="costsGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#005C53" stopOpacity={0.8} /> {/* Türkis */}
                  <stop offset="100%" stopColor="#005C53" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" />
              <YAxis tick={<CustomYAxisTick />} axisLine={{ strokeWidth: 0 }} tickLine={{ strokeWidth: 2, stroke: '#666' }} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="sales"
                stroke="#005C53"
                strokeWidth={3}
                fill="url(#salesGradient)"
                name="Monatsumsätze"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="costs"
                stroke="#800000"
                strokeWidth={3}
                fill="url(#costsGradient)"
                name="Kosten"
                dot={<CustomDot />}
              />
            </LineChart>
          </ResponsiveContainer>
          </Card.Body>
          </Card>
        </Col>

      
        <Col md={6}>
        <Card className="info-box">
            <Card.Body>
        <ResponsiveContainer width="100%" height={400}>
                <LineChart data={data2} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}                >
           <defs>
                <linearGradient id="lastYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#9FC131" stopOpacity={0.8} /> {/* Grün-Gelb */}
                  <stop offset="100%" stopColor="#9FC131" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="currentYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#DBF227" stopOpacity={0.8} /> {/* Gelb */}
                  <stop offset="100%" stopColor="#DBF227" stopOpacity={0.2} />
                </linearGradient>
                <linearGradient id="previousYearGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#D6D58E" stopOpacity={0.8} /> {/* Hellblau-Grau */}
                  <stop offset="100%" stopColor="#D6D58E" stopOpacity={0.2} />
                </linearGradient>
              </defs>
              <XAxis dataKey="month" />
              <YAxis tick={<CustomYAxisTick />} axisLine={{ strokeWidth: 0 }} tickLine={{ strokeWidth: 2, stroke: '#666' }} />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="lastYear"
                stroke="#9FC131"
                strokeWidth={3}
                fill="url(#lastYearGradient)"
                name="Vorjahr"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="currentYear"
                stroke="#DBF227"
                strokeWidth={3}
                fill="url(#currentYearGradient)"
                name="Aktuelles Jahr"
                dot={<CustomDot />}
              />
              <Line
                type="monotone"
                dataKey="previousYear"
                stroke="#D6D58E"
                strokeWidth={3}
                fill="url(#previousYearGradient)"
                name="Nächstes Jahr"
                dot={<CustomDot />}
              />
                </LineChart>
          </ResponsiveContainer>
          </Card.Body>
          </Card>
        </Col>  </Row>
        )}

      <Row className="orders-list-row">
        {/* Liste mit aktuellen Aufträgen */}
        <Col>
          <Card>
            <Card.Body style={{ maxHeight: '400px', overflow: 'auto' }}>
              <Card.Title>Nächste Termine</Card.Title>
              <Card.Text>
              <AuftragsListeElement  />
                {/* Hier kannst du die Auftragsliste rendern */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="orders-list-row">
        {/* Liste mit aktuellen Aufträgen */}
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Offene Aufgaben</Card.Title>
              <Card.Text>
              {tasks.map(task => (
                                        <TaskElement
                                            key={task.id}
                                            task_id={task.task_id}
                                            status={task.task_status}
                                            task_typ={task.task_typ}
                                            preis={task.preis}
                                            task_bezugsobjekt={task.task_bezugsobjekt}
                                            zusatzinfo={task.zusatzinfo}
                                            showDetails={() => console.log('Details anzeigen für Aufgabe:', task.id)}
                                        />
                                    ))}
                {/* Hier kannst du die Auftragsliste rendern */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

    </Container>
    </div>
  );
};

export default DashboardLayout;
