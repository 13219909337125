import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios'
import Test from './Testumgebung';
import Navbar from './navbar';
import React, { useEffect, useState } from 'react';





function AuftragAendernTrainer() {
  const { auftragsnummer } = useParams();
  const [auftrag, setAuftrag] = useState(null);
  const [begruendung, setBegruendung] = useState('');
  const [neuer_termin, setNeuer_termin] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [aenderung, setAenderung] = useState(false);



  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`http://34.240.112.160:80/auftrag/${auftragsnummer}`)
      .then(response => {
        setAuftrag(response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, [auftragsnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird

  if (!auftrag) {
    return <div>Loading...</div>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setShowPopup(true);
        // Hier kannst du die Formulardaten vorbereiten
      const neuerTerminFormatted = new Date(neuer_termin).toISOString();

        // Finaler JSON
        const formData = {
          auftrag_nummer: auftragsnummer,
          auftrag_projekt: auftrag.auftrag_projekt,
          auftrag_datum: auftrag.auftrag_datum,
          auftrag_auftraggeber: auftrag.auftrag_auftraggeber,
          auftrag_thema: auftrag.auftrag_thema,
          auftrag_beschreibung: auftrag.auftrag_beschreibung,
          auftrag_trainer: 20,
          auftrag_umfang: auftrag.auftrag_umfang,
          begruendung: begruendung,
          neuerTermin: neuerTerminFormatted,
          aenderung:aenderung
        };

        // Hier kannst du den POST-Request an deinen Express-Server senden
        const response = await axios.post('http://34.240.112.160:80/createAenderung', JSON.stringify(formData), {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        

        setTimeout(() => {
          setShowPopup(false);
        }, 2000);
        // Ausgehende ServerFrase
        console.log('Request Body:', formData);
        // Hier kannst du mit der Antwort vom Server arbeiten (falls notwendig)
        console.log('Serverantwort:', response.data);
    } catch (error) {
        console.error('Fehler beim Senden des Formulars:', error);
        setShowPopup(false);
    }
};


  

  return (
    <div>
          <Navbar/>  
    

      <h2>Änderung des Termins // {auftragsnummer}  </h2>
      {showPopup && (
        
        <div className="popup">
          <h3>Erfassung im System </h3>
          <div class="spinner-grow text-primary" role="status">
          <span class="sr-only"></span> 
         </div>
         <div class="spinner-grow text-primary" role="status">
          <span class="sr-only"></span> 
         </div>
                   <div class="spinner-grow text-primary" role="status">
          <span class="sr-only"></span> 
         </div>
        </div>
      )}


{!showPopup && (
  
      <form className="row g-3" onSubmit={handleSubmit}>
      <div className="col-md-2">
        <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
        <input type="text" readonly className="form-control" id="auftrag_nummer" value={auftrag.auftrag_nummer} disabled readonly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">Projektnummer</label>
        <input type="text" readonly className="form-control" id="Projektnummer" value={auftrag.auftrag_projekt}  disabled readOnly/>
      </div>
      <div className="col-md-2">
        <label htmlFor="Einsatzdatum" className="form-label">Einsatzdatum</label>
        <input type="text" readonly className="form-control" id="Einsatzdatum" value={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')} disabled readonly/>
      </div>
      <div className="col-md-6">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" id="Auftraggeber" value={auftrag.auftrag_auftraggeber} disabled readonly/>
      </div>
      <div className="col-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" className="form-control" id="Thema" value={auftrag.auftrag_thema} disabled readonly/>
      </div>
      <div className="col-6">
  
      </div>
      <div className="bg-light border"></div>
      <div className="col-md-2">
        <label htmlFor="NeuTermin" className="form-label"  >Neuer Termin</label>
        <input   className="form-control" id="NeuTermin" type="date" value={neuer_termin}   onChange={(e) => setNeuer_termin(e.target.value)} />
      </div>
      <div className="col-md-6">
        <label htmlFor="Begruendung" className="form-label">Begründung</label>
        <input type="text" className="form-control" id="Begruendung" value={begruendung}   onChange={(e) => setBegruendung(e.target.value)} required />
      </div>
      <div className="col-4">

      </div>
      <div className="col-2">

      </div>
      <div className="col-md-6">
        <label htmlFor="Art" className="form-label">Art der Änderung</label>
        <select id="Art" className="form-select"  value={aenderung} onChange={(e) => setAenderung(e.target.value)}   required  >
        <option></option>
        <option>Terminänderung Trainerwunsch</option>
          <option>Terminänderung Kundenwunsch</option>
          <option>Storno durch Kunde</option>
        </select>
      </div>
      <div className="bg-light border"></div>
      <button type="submit" className="btn btn-primary">Änderung absenden</button>
      <Link to={`/auftrag/${auftragsnummer} `}> <button type="ab" className="btn btn-danger">Abbrechen</button> </Link>
    </form>
   
)} </div> // die beiden davor 
    
  );
}

export default AuftragAendernTrainer;