import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';

const DataTable = ({ data }) => {
  const [sortConfig, setSortConfig] = useState(null);
  const [filteredData, setFilteredData] = useState(data);

  // Funktion zum Sortieren der Daten
  const sortData = (key) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });

    setFilteredData(sortedData);
    setSortConfig({ key, direction: 'ascending' });
  };

  // Funktion zum Umkehren der Sortierreihenfolge
  const handleSort = (key) => {
    if (sortConfig && sortConfig.key === key) {
      const direction = sortConfig.direction === 'ascending' ? 'descending' : 'ascending';
      setSortConfig({ ...sortConfig, direction });
    } else {
      sortData(key);
    }
  };

  // Funktion zum Filtern der Daten
  const handleFilter = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredResults = data.filter(item =>
      Object.values(item).some(value =>
        typeof value === 'string' && value.toLowerCase().includes(searchTerm)
      )
    );
    setFilteredData(filteredResults);
  };

  return (
    <div>

      <input type="text"   className="form-control" placeholder="Suchen..." onChange={handleFilter} />
      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('Name')}>Name</th>
            <th onClick={() => handleSort('Vorname')}>Vorname</th>
            <th onClick={() => handleSort('Adresse')}>Adresse</th>
            <th onClick={() => handleSort('E-Mailadresse')}>E-Mailadresse</th>
            <th onClick={() => handleSort('Telefonnummer')}>Telefonnummer</th>
            <th onClick={() => handleSort('Geburtstag')}>Geburtstag</th>
            <th onClick={() => handleSort('Thema')}>Thema</th>
            <th onClick={() => handleSort('mögliche Tage')}>mögliche Tage</th>
            <th onClick={() => handleSort('freie Tage')}>freie Tage</th>

            <th onClick={() => handleSort('PPP')}>PPP</th>
            <th onClick={() => handleSort('Vita')}>Vita (Anhang)</th>
            <th onClick={() => handleSort('hospitiert')}>hospitiert</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map((row, index) => (
            <tr key={index}>
              <td>{row.Name}</td>
              <td>{row.Vorname}</td>
              <td>{row.Adresse}</td>
              <td>{row["E-Mailadresse"]}</td>
              <td>{row.Telefonnummer}</td>
              <td>{row.Geburtstag}</td>
              <td>{row.Thema}</td>
              <td>{row["mögliche Tage"]}</td>
              <td>{row["freie Tage"]}</td>

              <td>{row.PPP}</td>
              <td>{row["Vita (Anhang)"]}</td>
              <td>{row.hospitiert}</td>
              <td><Button variant="danger">Bearbeiten</Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTable;
