import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import RechnungsterminElement from "../rechnungs_element";

function AvArchiv() {
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    axios.get("http://34.240.112.160:80/auftrag")
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const status = auftrag.auftrag_status.toLowerCase();

    return (
      status === "archiviert" && // Nur Aufträge im Status "archiviert" berücksichtigen
      (auftrag.auftrag_thema.toLowerCase().includes(lowerCaseSearchTerm) ||
        auftrag.auftrag_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm) ||
        auftrag.auftrag_id.toString().includes(lowerCaseSearchTerm))
    );
  });

  const showAuftragDetail = (auftragsid) => {
    console.log(auftragsid);
    // Hier können Sie die Logik zum Anzeigen der Detailansicht implementieren
  };

  return (
    <Fragment>
      <div className="col-mb-6">
        <h3>Auftragsarchiv</h3>
      </div>

      <div className="input-group mb-3">
        {/* Suchfeld direkt in der TerminListe */}
        <input
          type="text"
          className="form-control"
          placeholder="Aufträge durchsuchen..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="list-group">
        {filteredAuftrage.map((auftrag, index) => (
          <Fragment key={index}>
            <RechnungsterminElement
              showDetails={() => showAuftragDetail(auftrag.auftrag_nummer)}
              datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
              thema={auftrag.auftrag_thema}
              auftragsnummer={auftrag.auftrag_id}
              status={auftrag.auftrag_status}
              auftraggeber={auftrag.auftrag_auftraggeber}
              eingang={auftrag.auftrag_eingangsrechnung_zahlung}
              ausgang={auftrag.auftrag_ausgangsrechnung_zahlung}
            />
          </Fragment>
        ))}
      </div>
    </Fragment>
  );
}

export default AvArchiv;
