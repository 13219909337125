import React, { Fragment, useState,useEffect } from 'react';
import axios from 'axios';
import useAdminCheck from './rechte_admin';
import Accordion from 'react-bootstrap/Accordion';

const Einstellungen = () => {

  const vollname = localStorage.getItem("Vollname");
  const username = localStorage.getItem("Vollname");
  const [newPassword, setNewPassword] = useState('');
  const [Password, setPassword] = useState('');
   const [role, setRole] = useState('');
  const [message, setMessage] = useState('');
    const unbekannt = '';
    const [trainer, setTrainer]=useState('');
    const schluessel = 'f6eaELeHVG8ugAn';

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
          // Passwortänderungsanforderung an den Server senden
          const response = await axios.put('http://34.240.112.160:80/pwchange', { username, newPassword });
          setMessage(response.data.message);
        } catch (error) {
          setMessage('Fehler beim Ändern des Passworts');
          console.error('Fehler beim Ändern des Passworts:', error);
        }
      };
      const trainer_safe = async (e) => {
        e.preventDefault();
        
        try {
          // Passwortänderungsanforderung an den Server senden
          const response = await axios.put('http://34.240.112.160:80/trainer-data', { schluessel, trainer });
          setMessage(response.data.message);
        } catch (error) {
          setMessage('Fehler beim Ändern der Benutzerdaten');
          console.error('Fehler beim Ändern der Benutzerdaten:', error);
        }
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            const auftragResponse = await axios.get(`http://34.240.112.160:80/trainer-data/${username}`, {schluessel});
            setTrainer(auftragResponse.data);

          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, []);
      

  
  return (
    <Fragment>
      <div className="container mt-5">
        <h2 className="text-center mb-4">Einstellungen</h2>
        <div className="row">
          {/* Erstes Formular links */}
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
            <Accordion.Header>User </Accordion.Header>
            <Accordion.Body>
                    <div className="mb-3">
                      <label htmlFor="newPassword" className="form-label">Neues Passwort:</label>
                      <input name="newPassword" type="password" id="newPassword" className="form-control" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
                   
                    </div>
                    <button type="submit"  className="btn btn-primary">Passwort ändern</button>
                  </Accordion.Body>
      </Accordion.Item>



            <Accordion.Item eventKey="2">
            <Accordion.Header>Adresse </Accordion.Header>
            <Accordion.Body>
              <div className="mb-3">
                <label htmlFor="text" className="form-label">Name:</label>
                <input type="password" id="password" className="form-control" value={unbekannt} onChange={(e) => setPassword(e.target.value)}  />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Straße:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Ort:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">PLZ:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} />
              </div>     
              <div className="mb-4">
                <label htmlFor="role" className="form-label">E-Mail:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} />
              </div>   
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Telefon:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} />
              </div>  
              <button type="text" onClick={trainer_safe} className="btn btn-primary">Personendaten ändern</button>
              </Accordion.Body>
      </Accordion.Item>
                  <Accordion.Item eventKey="1">
                  <Accordion.Header>Steuern </Accordion.Header>
                  <Accordion.Body>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Steuernummer:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} disabled />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Umsatzsteuerregel:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} disabled />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Umsatzsteuernummer:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)}disabled />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Rechnungsnummer:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} disabled />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Bankverbindung:</label>
                <input type="text" id="role" className="form-control" value={unbekannt} onChange={(e) => setRole(e.target.value)} disabled/>
              </div>
              </Accordion.Body>
      </Accordion.Item>


      </Accordion>
              

              {/* <button type="submit" className="btn btn-primary">Ändern</button>*/}
            </form>
            {message && <p className="mt-3 text-center">{message}</p>}
          </div>
          {/* Zweites Formular rechts */}
          <div className="col-md-6">
            <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Benutzername:</label>
                <input type="" id="" className="form-control" value="restricted" disabled  />
              </div>
              <div className="mb-4">
                <label htmlFor="password" className="form-label">Rechteprofil</label>
                <input type="password" id="role" className="form-control" value="restricted"  disabled />
              </div>
              <div className="mb-4">
                <label htmlFor="role" className="form-label">Trainername</label>
                <input type="text" id="role" className="form-control" value={vollname}  disabled/>
              </div>

             
            </form>
            {message && <p className="mt-3 text-center">{message}</p>}
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Einstellungen;
