import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

const NewAnsprechpartnerModal = ({ show, handleClose }) => {
  const [formData, setFormData] = useState({
    firmenname: '',
    ansprechpartner: '',
    strasse: '',
    plz: '',
    ort: '',
    email: '',
    telefon: '',
    kontakttyp: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    // Send form data to backend endpoint
    axios.post('http://34.240.112.160:80/neuer-ap', formData)
      .then(response => {
        console.log('Neuer Ansprechpartner erfolgreich angelegt:', response.data);
        // Hier können Sie je nach Bedarf eine Benachrichtigung anzeigen oder zur nächsten Seite navigieren
        handleClose(); // Schließen Sie das Modal nach erfolgreicher Verarbeitung
      })
      .catch(error => {
        console.error('Fehler beim Anlegen des neuen Ansprechpartners:', formData,'DATEN');
      });
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Neuer Ansprechpartner anlegen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formFirmenname">
            <Form.Label>Firmenname</Form.Label>
            <Form.Control type="text" name="firmenname" value={formData.firmenname} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formAnsprechpartner">
            <Form.Label>Ansprechpartner</Form.Label>
            <Form.Control type="text" name="ansprechpartner" value={formData.ansprechpartner} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formStrasse">
            <Form.Label>Str.</Form.Label>
            <Form.Control type="text" name="strasse" value={formData.strasse} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formPlz">
            <Form.Label>PLZ</Form.Label>
            <Form.Control type="number" name="plz" value={formData.plz} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formOrt">
            <Form.Label>Ort</Form.Label>
            <Form.Control type="text" name="ort" value={formData.ort} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formOrt">
            <Form.Label>E-Mail</Form.Label>
            <Form.Control type="text" name="email" value={formData.email} onChange={handleChange} />
          </Form.Group>
          <Form.Group controlId="formOrt">
            <Form.Label>Telefon</Form.Label>
            <Form.Control type="text" name="telefon" value={formData.telefon} onChange={handleChange} />
          </Form.Group>

          <Form.Group controlId="formKontakttyp">
            <Form.Label>Kontakttyp</Form.Label>
            <Form.Control as="select" name="kontakttyp" value={formData.kontakttyp} onChange={handleChange}required>
              <option value="">Bitte wählen</option>
              <option value="10">Rechnungsempfänger</option>
              <option value="20">Fachlicher Ansprechpartner</option>
            </Form.Control>
          </Form.Group>
          {/* Add other form fields here */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Abbrechen
        </Button>
        <Button variant="danger" onClick={handleSubmit}>
          Neuen Ansprechpartner anlegen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewAnsprechpartnerModal;
