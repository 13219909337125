import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

class TaskElement extends Component {
  state = {};

  getStatusClassName = () => {

    console.log(this.props.task_typ)
    switch (this.props.task_typ) {
      case 10:
        return "Neue Reisespesen zu prüfen";
      case 20:
        return "Materialbestellung  für Versand vorbereiten";
      case 30:
        return "Neuer gewonnener Deal - Projekt anlegen";
      case 40:
      return "Neue Projektrechnung erforderlich";
      default:
        return "Unbekannte Aufgabe"; // Fallback für unbekannte Statuswerte
    }
  };

  handleQuittung = () =>{
    axios.put(`http://34.240.112.160:80/task-done/${this.props.task_id}`)     
  };

  getLinkClassName = () => {

    console.log(this.props.task_typ)
    switch (this.props.task_typ) {
      case 10:
        return "checkmate";
      case 20:
        return "order";
      case 30:
        return "hubspot";
      case 40:
      return "auftrag_d";
      default:
        return "Unbekannte Aufgabe"; // Fallback für unbekannte Statuswerte
    }
  };

  render() {
    const statusClassName = this.getStatusClassName();
    const linkadresse =this.getLinkClassName();
    return (
      <div className="list-group">
      <div className="list-group-item list-group-item-action">
        <div className="d-flex w-100 justify-content-between">
          <div className="col-md-2">
            <h5 className="mb-1">{statusClassName} </h5>
          </div>
          <div>
            <span></span> 
          </div>
        </div>
        <div className="d-flex w-100 justify-content-between">
          <div>{this.props.zusatzinfo}</div>
          <div>
            <Link to={`/${linkadresse}/${this.props.task_bezugsobjekt}`}>
              <button
                onClick={this.props.showDetails}
                type="button"
                className="btn btn-secondary"
              >
                Zur Aufgabe
              </button>
              
            </Link>
            
              <button
                onClick={this.handleQuittung}
                type="button"
                className="btn btn-danger"
              >
                Quittieren
              </button>
              
            
            {" "}
          </div>
        </div>

      </div>
      </div>
    );
  }
}

export default TaskElement;
