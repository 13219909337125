import React, { useEffect, useState } from 'react';
import { Link, Route, BrowserRouter as Router } from "react-router-dom";

import { Accordion,Table, Card, Button  } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown,faAngleUp,faRightToBracket,faTrash,faThumbsUp    } from "@fortawesome/free-solid-svg-icons";
import axios from 'axios'; // Importieren Sie Axios

const inlineCSS = `
.centered-field {
  margin: 0 auto;
  max-width: 1400px;
  border: 1px none #ccc;
  padding: 20px;
}
`;
function Waterfall() {
  const [projekte, setProjekte] = useState([]);
  const [openAuftraege, setOpenAuftraege] = useState({});
  const [thumbsUpClicked, setThumbsUpClicked] = useState({});

  const toggleAuftraege = (projektId) => {
    setOpenAuftraege(prevState => ({
      ...prevState,
      [projektId]: !prevState[projektId]
    }));
  };

  useEffect(() => {
    const fetchProjekte = async () => {
      try {
        const trainername = localStorage.getItem("Vollname");
        const response = await axios.get(`http://34.240.112.160:80/tr/spesen/${trainername}`);
        setProjekte(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchProjekte();
  }, []);

  const DelMat = async (material_id) => {
    axios.put(`http://34.240.112.160:80/tr/spesen/del/${material_id}`)
      .then(response => {
        // Daten in den State setzen
        setProjekte(response.data);
        console.log('data', response.data)

      })
      
      .catch(error => {
        // Fehlerbehandlung
        console.error('Error fetching data:', error);
      });
  };

  const handleThumbsUpClick = (material_id) => {
    setThumbsUpClicked(prevState => ({
      ...prevState,
      [material_id]: true
    }));
    DelMat(material_id); // Hier kannst du die entsprechende Funktion für Daumen-hoch-Action einfügen
  };

  if(!projekte){
    <h1>Meine Projekte</h1>

  }
  
  return (
    <div className="centered-field">
            <style>{inlineCSS}</style>

      <h1>Meine Projekte</h1>
      <h5>Bitte in den Auftrag klicken zum Löschen von Reisespesen</h5>
      <Accordion defaultActiveKey="0">
        {projekte.map((item, index) => (
          <Accordion.Item eventKey={index.toString()} key={index}>
            <Accordion.Header>
              {item.projekt.projekt_auftraggeber} -  {item.projekt.projekt_thema} ( Projektwert: {item.projekt.summeAuftraege} EUR )
            </Accordion.Header>
            <Accordion.Body>
              <p><strong>Umsatzsteuerkennzeichen:</strong> 
              {item.projekt.projekt_umsatzsteuer === 1 ? (
                <FontAwesomeIcon icon={faAngleUp} style={{ color: "green", marginLeft: "auto" }} />
              ) : (
                <FontAwesomeIcon icon={faAngleDown} style={{ color: "red", marginLeft: "auto" }} />
              )}
              
              
          </p>
              <h4>Aufträge:</h4>
              <Accordion defaultActiveKey="0">
                {item.projekt.auftraege.map((auftrag, auftragIndex) => (
                  <Accordion.Item eventKey={auftragIndex.toString()} key={auftragIndex}>
                    <Accordion.Header>
                    {auftrag.datum} // {auftrag.beschreibung}     //     {auftrag.tagessatz}   EUR  
                           <Link to={`/tr/auftrag/${auftrag.auftrag_id}`}>  <FontAwesomeIcon icon={faRightToBracket} style={{ color: "green", marginLeft: "auto" }} /> </Link> 
                     </Accordion.Header>
                    <Accordion.Body>
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Art</th>
                            <th>Anzahl</th>
                            <th>Gesamtpreis</th>
                          </tr>
                        </thead>
                        <tbody>
                          {auftrag.materialien.map((material, materialIndex) => (
                            <tr key={materialIndex}>
                              <td>{material.name}  -{material.material_id}</td>
                              <td>{material.anzahl}</td>
                              <td>{material.einzelpreis}</td>
                              <FontAwesomeIcon icon={thumbsUpClicked[material.material_id] ? faThumbsUp : faTrash} style={{ color: thumbsUpClicked[material.material_id] ? "green" : "red", marginLeft: "auto" }} onClick={() => handleThumbsUpClick(material.material_id)} /> {/* Hier wird das Symbol basierend auf dem Zustand thumbsUpClicked geändert */}
                                 </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
}


export default Waterfall;