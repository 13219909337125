import React, { useState } from 'react';
import axios from 'axios';
const EmailForm = ({ defaultRecipient, defaultSubject, defaultText, onClose, projektname, projektnummer }) => {
  const [recipient, setRecipient] = useState(defaultRecipient || '');
  const [subject, setSubject] = useState(defaultSubject || '');
  const [text, setText] = useState(defaultText || '');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Sende eine POST-Anfrage an den Server mit den Formulardaten
      const response = await axios.post('http://34.240.112.160:80/send-email', {
        recipient,
        subject,
        text,
        projektname,
        projektnummer
      });

      console.log('E-Mail erfolgreich gesendet:', response.data);

      // Nach erfolgreichem Versand kannst du onClose() aufrufen, um das Modal zu schließen
      onClose();
    } catch (error) {
      console.error('Fehler beim Senden der E-Mail:', error);
    }
  };
  
  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="recipient" className="form-label">Empfänger</label>
        <input
          type="email"
          className="form-control"
          id="recipient"
          value={recipient}
          onChange={(e) => setRecipient(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="subject" className="form-label">Betreff</label>
        <input
          type="text"
          className="form-control"
          id="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="text" className="form-label">Nachricht</label>
        <textarea
          className="form-control"
          id="text"
          rows="5"
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        ></textarea>
      </div>
      <button type="submit" className="btn btn-primary"onClick={handleSubmit}>Senden</button>
    </form>
  );
};

export default EmailForm;