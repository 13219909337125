import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { Modal, Button } from 'react-bootstrap';
import Navbar from '../navbar';
import { useAuth } from '../AuthProvider';
import useAdminCheck from '../rechte_admin';

function Spesenprüfung() {
        
          useAdminCheck();
  

        const [token, setToken] = useState(() => localStorage.getItem('Token') || null);
        const [role, setRole] = useState(() => localStorage.getItem('Role') || null);
        const [success, setSuccess] = useState(false);
        const { materialnummer } = useParams();
        const [trainerOptions, setTrainerOptions] = useState([]);
        const [Fields, setFields] = useState({
            Auftraggeber: '',
            Auftragsnummer: '',
            Einsatzdatum: '',
            Trainer:'',

        });



        const [editFields, setEditFields] = useState({
            materialid: '',
            materialname: '',
            materialanzahl: '',
            materialprojekt: '',
            materialgesamtpreis: '',
            materialabgrechnungsstatus:'',
            materialgenehmigt:'',
            materialauftragsnummer:'',
            beschreibung:'',

        });
      
        const [showModal, setShowModal] = useState(false);
        const [dateipfad, setDateipfad] =useState('')
        // Hinzufügen einer neuen Funktion zum Öffnen des Modals
        const handleShowModal = () => setShowModal(true);
      
        // Hinzufügen einer neuen Funktion zum Schließen des Modals
        const handleCloseModal = () => setShowModal(false);
      
        const handleRechnungErstellenClick = () => {
          // Hier kannst du die Logik für die Rechnungserstellung implementieren
          console.log('Rechnung erstellen...');
          // Zum Beispiel: Führe eine API-Anfrage für die Rechnungserstellung durch
          // ...
        
          // Schließe das Modal nach der Verarbeitung
          setShowModal(false);  // setze setShowModal auf false, um das Modal zu schließen
        };

        const handleGenehmigung =()=>{

        axios.put(`http://34.240.112.160:80/material-auftrag/${materialnummer}`)
        setSuccess(true);
        axios.put(`http://34.240.112.160:80/task-done/${this.props.task_id}`)     


        };
        
        const handleBeleganzeigenClick = () => {
            window.location.href = dateipfad; // Hier wird der Dateipfad geöffnet
          };
       

          useEffect(() => {
            // Hier wird der Materialauftrag abgerufen
            axios.get(`http://34.240.112.160:80/material-auftrag/${materialnummer}`)
                .then(materialResponse => {
                    console.log("Materialauftrag Data:", materialResponse.data);
                    setEditFields({
                        materialid: materialResponse.data.material_id,
                        materialname: materialResponse.data.name,
                        materialanzahl: materialResponse.data.anzahl,
                        materialprojekt: materialResponse.data.zuprojekt,
                        materialgesamtpreis: materialResponse.data.gesamtpreis,
                        materialabgrechnungsstatus: materialResponse.data.abgerechnet,
                        materialgenehmigt: materialResponse.data.genehmigt,
                        materialauftragsnummer: materialResponse.data.material_auftrag,
                        beschreibung: materialResponse.data.beschreibung,
                    });
        
                    // Nachdem die Materialauftragsdetails erfolgreich geladen wurden, wird der Auftrag abgerufen
                    axios.get(`http://34.240.112.160:80/auftrag/${materialResponse.data.material_auftrag}`)
                        .then(auftragResponse => {
                            console.log("Auftrag Data:", auftragResponse.data);
                            setFields({
                                Auftraggeber: auftragResponse.data.auftrag_auftraggeber,
                                Auftragsnummer: auftragResponse.data.auftrag_auftragsnummer,
                                Trainer: auftragResponse.data.auftrag_trainer,
                                Thema: auftragResponse.data.auftrag_thema,
                                Einsatzdatum: auftragResponse.data.auftrag_datum,
                            });
        
                            // Nachdem die Daten für den Auftrag erfolgreich geladen wurden, wird der Endpunkt `files-mt` aufgerufen
                            axios.get(`http://34.240.112.160:80/files-mt/${materialnummer}`)
                                .then(filesResponse => {
                                    console.log("Files MT Data:", filesResponse.data);
                                    setDateipfad(filesResponse.data.files_url);
                                    // Hier kannst du die Daten von `files-mt` weiterverarbeiten
                                })
                                .catch(filesError => {
                                    console.error('Error fetching files-mt data:', filesError);
                                });
                        })
                        .catch(auftragError => {
                            console.error('Error fetching auftrag details:', auftragError);
                        });
                })
                .catch(materialError => {
                    console.error('Error fetching material-auftrag details:', materialError);
                });
        }, [materialnummer]);

        const buttonSafe = () => {
          console.log('Details:',editFields);
          axios.put(`http://34.240.112.160:80/spesen-auftrag/${materialnummer}`, editFields)
          .then(response => {
            console.log("Order successfully placed:", response.data);
            // Close the popup

          })
          .catch(error => {
            console.error("Error placing order:", error);
          });


        };
       
        const buttonClick = () => {
          console.log('Error fetching auftrag details:',editFields);
        };
        
        const handleInputChange = (e) => {
          const { name, value } = e.target;
          setEditFields({
            ...editFields,
            [name]: value,
          });
        };

        const convertISODateToInputFormat = (isoDate) => {
          if (!isoDate) return ""; // Sicherstellen, dass ein Datum vorhanden ist
        
          const datePortion = isoDate.split("T")[0];
          return datePortion;
        };
       
  return (
    <div>
        
          <div className="container">
      Spesengenehmigung


      <h2>{materialnummer} // Spesen </h2>
      <form className="row g-3">
      <div className="col-md-2">
        <label htmlFor="Auftragsnummer" className="form-label">Auftragsnummer</label>
        <input type="text" readonly className="form-control" id="Auftragsnummer" value={editFields.materialauftragsnummer} disabled ReadOnly/>
      </div> 

      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">Projektnummer</label>
        <input type="text" readonly className="form-control" id="Projektnummer" value={editFields.materialprojekt} disabled readOnly/>
      </div>

      <div className="col-md-2">
        <label htmlFor="Einsatzdatum" className="form-label">Einsatzdatum</label>
        <input
              type="date"
              className="form-control"
              id="datum"
              name="datum"
              value={convertISODateToInputFormat(Fields.Einsatzdatum)}
              onChange={handleInputChange}
              disabled
            />      
</div>

<div className="col-md-8">
        <label htmlFor="Auftragsnummer" className="form-label">Auftraggeber</label>
        <input type="text" readonly className="form-control" id="Auftragsnummer" value={Fields.Auftraggeber} disabled ReadOnly/>
      </div>      <div className="col-md-2"></div>
      <div className="col-md-4">
        <label htmlFor="Auftraggeber" className="form-label">Trainer</label>
        <input type="text" readonly className="form-control" id="Auftraggeber" value={Fields.Trainer} disabled ReadOnly/>
      </div>
      <div className="col-md-4">
        <label htmlFor="Thema " className="form-label">Thema</label>
        <input type="text" readonly className="form-control" id="Thema" value={Fields.Thema} disabled ReadOnly/>
      </div>
      <div className="col-8">
      <label htmlFor="Spesenart" className="form-label">Spesenart</label>
      <input
              type="text"
              className="form-control"
              id="Spesenart"
              name="Spesenart"
              value={editFields.materialname}
              onChange={handleInputChange}
              disabled
            />  
              </div>
              <div className="col-8">
      <label htmlFor="Spesenart" className="form-label">Beschreibung der Wegstrecke</label>
      <input
              type="text"
              className="form-control"
              id="Spesenart"
              name="Spesenart"
              value={editFields.beschreibung}
              onChange={handleInputChange}
              
            />  
              </div>
              <div className="col-8">
      <label htmlFor="Betrag" className="form-label">Betrag</label>
      <input
              type="text"
              className="form-control"
              id="Betrag"
              name="Betrag"
              value={editFields.materialgesamtpreis}
              onChange={handleInputChange}
              disabled
            />  
              </div>
              <div className="col-8">
      <label htmlFor="Menge" className="form-label">Menge</label>
      <input
              type="text"
              className="form-control"
              id="Menge"
              name="Menge"
              value={editFields.materialanzahl}
              onChange={handleInputChange}
              disabled
            />  
              </div>
              
              <div className="col-11">    
              <button type="button" className="btn btn-secondary mb-2 w-75" onClick={handleBeleganzeigenClick}> Beleganzeigen</button>   
              </div>
              
      <div className="col-2">
      <Link to={/checkliste/}>   <button type="submit" className={`btn mb-2 w-100 ${success ? 'btn-success' : 'btn-danger'}`} onClick={handleGenehmigung}>Genehmigen</button>   </Link>  
              </div>
              <div className="col-2"></div>   <div className="col-2"></div>
              <div className="col-2">
      <Link to={/checkliste/}>   <button type="submit" className="btn btn-danger mb-2 w-100" >Ablehnen</button>   </Link>  
              </div>

    </form>
    </div>
    <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Rechnung erstellen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte wählen Sie die Abrechnungsart:</p>
          {/* Hier kannst du weitere Inhalte des Modals hinzufügen */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
        <Button variant="secondary" style={{ width: '45%', marginRight: '5px' }} onClick={handleCloseModal}>
            Einzelabrechnung
          </Button>
          <Button variant="primary" style={{ width: '45%', marginRight: '5px' }} onClick={handleRechnungErstellenClick}>

            Sammelabrechnung
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Spesenprüfung;