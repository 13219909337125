import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../navbar";
import Termin from "../termin";
import Accordion from 'react-bootstrap/Accordion';
import Material from "../material";
import CustomAlert from "../customAlert";
import ProjektAuftrag from "../projekt_auftrag_element";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import FileUpload from "../FileUpload";
import FileElement from "../file-element";
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function Materialbestellung() {
  const { projektnummer } = useParams();
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projekt, setProjekt] = useState(null);
  const[ap, setAP] =useState(null);
  const [rap,setRAP]=useState(null);
  const[material,setMaterial]= useState([]);
  const [buttonColor, setButtonColor] = useState('danger');



  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");

  const handleAlertClose = () => {
    setShowAlert(false);
    setAlertType("");
    setAlertMessage("");
  };



  let editableDataInitialState = {
    projekt_id: "",
    projekt_auftraggeber: "",
    projekt_thema: "",
    projekt_beschreibung: "",
    projekt_umfang: "",
    projekt_tagessatz: "",
    projekt_umsatzsteuer: "",
    projekt_status: "",
    projekt_ma: "",
    ansprechpartner_name: "",
    ansprechpartner_firmenname: "",
    ansprechpartner_mail: "",
    ansprechpartner_ort: "",
    ansprechpartner_plz: "",
    ansprechpartner_strasse: "",
    ansprechpartner_telefon: "",
    r_ansprechpartner_name: "",
    r_ansprechpartner_firmenname: "",
    r_ansprechpartner_mail: "",
    r_ansprechpartner_ort: "",
    r_ansprechpartner_plz: "",
    r_ansprechpartner_strasse: "",
    r_ansprechpartner_telefon: "",
  };

  const [materialList, setMaterialList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  const handleMaterialClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  

  const handleAddOrder = () => {
    // Send data to the "mt-bestellen" endpoint
    const orderData = {
      material: selectedMaterial,
      quantity: quantity,
    };

    axios.post(`http://34.240.112.160:80/mt-bestellen/${projektnummer}`, orderData)
      .then(response => {
        console.log("Order successfully placed:", response.data);
        // Close the popup
        setShowModal(false);
      })
      .catch(error => {
        console.error("Error placing order:", error);
      });
  };

  useEffect(() => {
    // Lade Materialien beim Mounten der Komponente
    axios.get("http://34.240.112.160:80/mt-list")
      .then(response => {
        setMaterialList(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Materialien:", error);
      });
  }, []);



  const [editableData, setEditableData] = useState(editableDataInitialState);
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`http://34.240.112.160:80/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);

        // Hier editableData aktualisieren
        setEditableData(prevData => ({
          ...prevData,
          projekt_id: response.data.projekt_id,
          projekt_auftraggeber: response.data.projekt_auftraggeber,
          projekt_thema: response.data.projekt_thema,
          projekt_beschreibung: response.data.projekt_beschreibung,
          projekt_umfang: response.data.projekt_umfang,
          projekt_tagessatz: response.data.projekt_tagessatz,
          projekt_umsatzsteuer: response.data.projekt_umsatzsteuer,
          projekt_status: response.data.projekt_status,
          projekt_ma: response.data.projekt_ma,
        }));
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
}, [projektnummer]);

  
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`http://34.240.112.160:80/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird



  useEffect(() => {
    // Daten für die spezifische Projektnummer abrufen
    axios.get(`http://34.240.112.160:80/projekt/${projektnummer}/auftraege`)
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen der Projektnummer ausgeführt wird

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  

  useEffect(() => {
    // Hier sollte die entsprechende Ansprechpartner-ID abgerufen werden (z.B., indem du Axios verwendest)
    if (projekt && projekt.projekt_ansprechpartner !== null && projekt.projekt_ansprechpartner !== undefined) {
      axios.get(`http://34.240.112.160:80/ap/${projekt.projekt_ansprechpartner}`)
        .then(response => {
          setAP(response.data ?? null);
          console.log('Ansprechpartner-Daten:', response.data);
  
          // Hier editableData aktualisieren
          setEditableData(prevData => ({
            ...prevData,
            ansprechpartner_name: response.data.ansprechpartner_name,
            ansprechpartner_firmenname: response.data?.ansprechpartner_firmenname,
            ansprechpartner_mail: response.data.ansprechpartner_mail ,
            ansprechpartner_ort: response.data.ansprechpartner_ort,
            ansprechpartner_plz: response.data.ansprechpartner_plz,
            ansprechpartner_strasse: response.data.ansprechpartner_strasse,
            ansprechpartner_telefon: response.data.ansprechpartner_telefon,
          }));
        })
        .catch(error => {
          console.error('Error fetching Ansprechpartner details:', error);
        });
    }
  }, [projekt?.projekt_ansprechpartner]); // Verwende optional chaining für zusätzlichen Schutz
  
  useEffect(() => {
    // Hier sollte die entsprechende Ansprechpartner-ID abgerufen werden (z.B., indem du Axios verwendest)
    if (projekt && projekt.projekt_rechnungsempfaenger !== null && projekt.projekt_rechnungsempfaenger !== undefined) {
      axios.get(`http://34.240.112.160:80/ap/${projekt.projekt_rechnungsempfaenger}`)
        .then(response => {
          setRAP(response.data);
          console.log('Rechnungsempfänger-Daten:', response.data);
  
          // Hier editableData aktualisieren
          setEditableData(prevData => ({
            ...prevData,
            r_ansprechpartner_name: response.data.ansprechpartner_name,
            r_ansprechpartner_firmenname: response.data.ansprechpartner_firmenname,
            r_ansprechpartner_mail: response.data.ansprechpartner_mail,
            r_ansprechpartner_ort: response.data.ansprechpartner_ort,
            r_ansprechpartner_plz: response.data.ansprechpartner_plz,
            r_ansprechpartner_strasse: response.data.ansprechpartner_strasse,
            r_ansprechpartner_telefon: response.data.ansprechpartner_telefon,
          }));
        })
        .catch(error => {
          console.error('Error fetching Rechnungsempfänger details:', error);
        });
    }
  }, [projekt?.projekt_rechnungsempfaenger]); // Verwende optional chaining für zusätzlichen Schutz
  

  useEffect(() => {
    // Lade Projektdaten beim Mounten der Komponente
    axios.get(`http://34.240.112.160:80/mt/${projektnummer}`)
      .then(response => {
        const filteredMaterial = response.data.filter(item => item.materialtyp === 2);
        setMaterial(filteredMaterial);

      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Projektdaten:", error);
      });
  }, []);

  
  const handleVersandBestaetigenClick = () => {
    generatePDF();
    // PUT-Request an den Endpunkt `mt-versand` senden
    axios.put(`http://34.240.112.160:80/mt-versand/${projektnummer}`)
      .then(response => {
        console.log('Versand bestätigt:', response.data);
        setButtonColor("success"); // Farbe des Buttons ändern
      })
      .catch(error => {
        console.error('Fehler beim Bestätigen des Versands:', error);
      });
  };


  const generatePDF = () => {
    const doc = new jsPDF();
    const tableColumn = ["Material", "Anzahl"];
    const tableRows = [];

    material.forEach(item => {
      const materialData = [
        item.name,
        item.anzahl.toString()
      ];
      tableRows.push(materialData);
    });

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("material_list.pdf");
  };



  
  if (!projekt) {
    return <div>Loading...</div>;
  }





  
// Event-Handler für Änderungen an den Formularfeldern
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setEditableData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};



const materaiRechnungErstellen = () =>{
  axios.get(`http://34.240.112.160:80/bill-go-mt/${projektnummer}`)
  .then(response => {
    setMaterial(response.data);
    console.log('Materialrechnung erfolgreich erstellt:', response.data);
    setButtonColor("success")
  })
  .catch(error => {
    console.error("Fehler bei Materialrechnung:", error);
  });

};
  const handleSpeichernClick = () => {
    // Hier kannst du die zu speichernden Daten aus den State-Variablen abrufen

    setAlertType("success");
    setAlertMessage("Erfolgreich gespeichert!");
    setShowAlert(true);

    const { projekt_id, projekt_auftraggeber, projekt_thema, projekt_beschreibung, projekt_umfang, projekt_tagessatz, projekt_umsatzsteuer, projekt_status,projekt_ma /* andere Daten */ } = projekt;
    const { ansprechpartner_name, ansprechpartner_firmenname, ansprechpartner_mail, ansprechpartner_ort, ansprechpartner_plz, ansprechpartner_strasse, ansprechpartner_telefon } = ap;
    const { r_ansprechpartner_name, r_ansprechpartner_firmenname, r_ansprechpartner_mail, r_ansprechpartner_ort, r_ansprechpartner_plz, r_ansprechpartner_strasse, r_ansprechpartner_telefon} = rap;
    // Erstelle ein Objekt mit den zu aktualisierenden Daten
    const updatedData = {
      projekt_id,
      projekt_auftraggeber,
      projekt_thema: editableData.projekt_thema,
      projekt_beschreibung: editableData.projekt_beschreibung,
      projekt_umfang: editableData.projekt_umfang,
      projekt_tagessatz: editableData.projekt_tagessatz,
      projekt_umsatzsteuer: editableData.projekt_umsatzsteuer,
      projekt_status: editableData.projekt_status,
      projekt_ma: editableData.projekt_ma,
      projekt_ansprechpartner: projekt.projekt_ansprechpartner,
      projekt_rechnungsempfaenger: projekt.projekt_rechnungsempfaenger,
      
    };
    const updatedDataAP = {
      ansprechpartner_name: editableData.ansprechpartner_name,
      ansprechpartner_firmenname: editableData.ansprechpartner_firmenname,
      ansprechpartner_mail: editableData.ansprechpartner_mail,
      ansprechpartner_ort: editableData.ansprechpartner_ort,
      ansprechpartner_plz: editableData.ansprechpartner_plz,
      ansprechpartner_strasse: editableData.ansprechpartner_strasse,
      ansprechpartner_telefon: editableData.ansprechpartner_telefon,
      // ... (andere Daten)
    };
    const updatedDataRAP = {
      ansprechpartner_name: editableData.r_ansprechpartner_name,
      ansprechpartner_firmenname: editableData.r_ansprechpartner_firmenname,
      ansprechpartner_mail: editableData.r_ansprechpartner_mail,
      ansprechpartner_ort: editableData.r_ansprechpartner_ort,
      ansprechpartner_plz: editableData.r_ansprechpartner_plz,
      ansprechpartner_strasse: editableData.r_ansprechpartner_strasse,
      ansprechpartner_telefon: editableData.r_ansprechpartner_telefon,
      // ... (andere Daten)


      

    };
    console.log('Daten der Aktualisierung:',updatedData );
    // Sende die Anfrage an den Server, um die Daten zu aktualisieren
    axios.put(`http://34.240.112.160:80/projekt/${projektnummer}`, updatedData)
      .then(response => {
        console.log('Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setProjekt(response.data);

        setAlertType("success");
        setAlertMessage("Erfolgreich gespeichert!");
        setShowAlert(true);
    
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Daten:', error);
      });
      axios
      .put(`http://34.240.112.160:80/ap/${projekt.projekt_ansprechpartner}`, updatedDataAP)
      .then((response) => {
        console.log('Ansprechpartner-Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setAP(response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Ansprechpartner-Daten:', error);
      });
  
    axios
      .put(`http://34.240.112.160:80/ap/${projekt.projekt_rechnungsempfaenger}`, updatedDataRAP)
      .then((response) => {
        console.log('Rechnungsempfänger-Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setRAP(response.data);
      })
      .catch((error) => {
        console.error('Fehler beim Aktualisieren der Rechnungsempfänger-Daten:', updatedDataRAP);
      });
  };

  if (Array.isArray(material)) {
    // Hier kannst du die map-Funktion sicher verwenden
    material.map(item => console.log(item));
  } else {
    console.error("material ist kein Array:", material);
  }
  




  return (
    <Fragment>

      <CustomAlert
        message={alertMessage}
        type={alertType}
        onClose={handleAlertClose}
      />

<CustomAlert
        message={alertMessage}
        type={alertType}
        onClose={handleAlertClose}
      />



      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <h3>{projektnummer}// Materialbestellung </h3>
       

          <div className="col-md-12">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">
            <div className="col-md-4">
        <label htmlFor="Projektnummer" className="form-label">No.</label>
        <input type="text" onChange={handleInputChange} className="form-control" id="Projektnummer" value={projekt.projekt_id} disabled />
      </div>

      <div className="col-md-8">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" name="projekt_auftraggeber" value={projekt.projekt_auftraggeber}   disabled onChange={(e) => {const updatedProjekt = { ...projekt, projekt_auftraggeber: e.target.value };
        setProjekt(updatedProjekt); }}/>
      </div>

      <div className="col-md-12">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" onChange={handleInputChange} name="projekt_thema"  className="form-control" id="Thema"  disabled value={editableData.projekt_thema} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input type="text"  className="form-control" name="projekt_beschreibung" value={editableData.projekt_beschreibung} disabled onChange={handleInputChange}/>
      </div>

      </form>

</div>

            
          </div>
          <div className="col-md-6">
          <button type="button" className="btn btn-secondary  mb-2 w-50"  onClick={handleSpeichernClick}>
                Versandlable drucken
              </button>
              <button type="button" onClick={generatePDF} className="btn btn-danger mb-2 w-50">
                Packliste
                </button>
              <button type="button" onClick={handleVersandBestaetigenClick} className="btn btn-danger mb-2 w-50">
                Versand bestätigen
              </button>

              <button type="button" onClick={materaiRechnungErstellen}   className={`btn btn-${buttonColor} mb-2 w-50`}>
               Materialrechnung erstellen
              </button>

              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
              <div>
              <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Versandadresse/Rechnungsadresse (Fachlicher Ansprechpartner)</Accordion.Header>
        <Accordion.Body>
      <div className="col-md-12">
        <label htmlFor="Firmenname" className="form-label">Ansprechpartner</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_firmenname" disabled value={editableData.ansprechpartner_firmenname}  />
      </div>
      <div className="col-md-12">
        <label htmlFor="Ansprechpartner" className="form-label">Ansprechpartner</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_name" disabled value={editableData.ansprechpartner_name} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Telefon" className="form-label">Telefon</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_telefon"disabled value={editableData.ansprechpartner_telefon}/>
      </div>
      <div className="col-md-12">
        <label htmlFor="Mail" className="form-label">Mail</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_mail" disabled value={editableData.ansprechpartner_mail}/>
      </div>
      <div className="col-md-12">
        <label htmlFor="Straße" className="form-label">Straße</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_strasse" disabled value={editableData.ansprechpartner_strasse} />
      </div>
      <div className="col-md-12">
        <label htmlFor="PLZ" className="form-label">PLZ</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_plz" disabled value={editableData.ansprechpartner_plz} />
      </div>
      <div className="col-md-12">
        <label htmlFor="Ort" className="form-label">Ort</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="ansprechpartner_ort" disabled value={editableData.ansprechpartner_ort} />
        </div>
      </Accordion.Body>
      </Accordion.Item>
	      </Accordion></div>

          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">



      <div className="bg-light border"></div>
      <div className="bg-light border"></div>
      <Accordion defaultActiveKey="2">

     
      <Accordion.Item eventKey="2">
        <Accordion.Header>Material</Accordion.Header>
                <Accordion.Body>

        <div className="list-group">
        <button type="button" onClick={handleMaterialClick} className="btn btn-secondary mb-2 w-100">
                Material hinzufügen
              </button>

                 {material.map(materialItem => (
        <Fragment key={materialItem.material_id}>
          <Material
            material={materialItem.name}
            anzahl={materialItem.anzahl}
          />
        </Fragment>
      ))}
            </div>  
  
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      </form>
            
          </div>
      {/* Modal for adding material */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Material hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Abbrechen
          </Button>
          <Button variant="primary" onClick={handleAddOrder}>
            Bestellung hinzufügen
          </Button>
        </Modal.Footer>
      </Modal>
          <div className="col-md-6">
          
            <div className="list-group">
              

            </div>
          </div>
        </div>
                 {/* POPUP MATERIAL */}
        <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title> Material hinzufügen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Bitte wählen das Material:</p>
          <select
            className="form-control"
            id="material"
            value={selectedMaterial}
            onChange={(e) => setSelectedMaterial(e.target.value)}
          >
            <option value="" disabled>Bitte auswählen</option>
            {materialList.map(materialItem => (
              <option key={materialItem.id} value={materialItem.material_bezeichnung}>
                {materialItem.material_bezeichnung}
              </option>
            ))}
          </select><p>
          <label htmlFor="quantity">Anzahl:</label></p>
          <input
            type="number"
            className="form-control"
            id="quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          {/* Hier kannst du weitere Inhalte des Modals hinzufügen */}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">

          <Button variant="primary" style={{ width: '45%', marginRight: '5px' }} onClick={handleAddOrder}>

            Material bestellen
          </Button>
        </Modal.Footer>
      </Modal>
      
      </div>


    </Fragment>
  );
}


export default Materialbestellung;
