import React, { Fragment, useState, useEffect } from "react";
import Termin from "../termin";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import Navbar from "../navbar";
import Test from "../Testumgebung";
import AuftragCheckiste from "../termin_checkliste";
import FileList from "../project_files";
import axios from "axios";  // Importiere Axios
import AuftragSuche from "../auftrag_suchen";
import useAdminCheck from "../rechte_admin";
import AuftragsListeElement from "../auftragsliste_element";

function TerminListeAv() {

  useAdminCheck();
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState(" ");

  useEffect(() => {
    // Hier wird Axios verwendet, um Daten aus der Datenbank abzurufen
    axios.get("http://34.240.112.160:80/auftrag")  // Passe die URL an
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Das leere Array stellt sicher, dass useEffect nur einmal beim Laden der Komponente ausgeführt wird



  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
    console.log(auftragsView);
  };


  // Sortiere die auftrage-Liste nach dem auftrag_datum

  const sortedAuftrage = auftrage.sort((b, a) => {
    const dateA = new Date(a.auftrag_datum);
    const dateB = new Date(b.auftrag_datum);
    return dateB - dateA;
  });




  const filteredAuftrage = auftrage.filter((auftrag) => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const isZugesagt = auftrag.auftrag_status === "zugesagt";
    const isInZukunft = new Date(auftrag.auftrag_datum) > new Date();
    return (

      (auftrag.auftrag_thema.toLowerCase().includes(lowerCaseSearchTerm) ||
        auftrag.auftrag_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm))
    );
  });
  return (


    
    <Fragment>
           <div> <div className="col-mb-6"><h3> Nächste Aufträge</h3></div> <div className="col-mb-6">           {/* Suchfeld direkt in der TerminListe */}

</div> </div> 

      <div className="list-group">

                         <AuftragsListeElement  />
          </div>



       
     
    </Fragment>
  );
}

export default TerminListeAv;
