import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";

import TerminTR from "./termin_tr";
class AuftragsListeElementTRAll extends React.Component {
    
  constructor(props) {
    super(props);
    this.state = {
      auftragsView: null,
      auftrage: [],
      searchTerm: "",
    };
  }


  
  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const vollname = localStorage.getItem("Vollname");
  
    try {
      const response = await axios.get(`http://34.240.112.160:80/tr/auftrag?name=${vollname}`);
      
      const sortedAuftrage = response.data.sort(
        (a, b) => new Date(a.auftrag_datum) - new Date(b.auftrag_datum)
      );
  
      const filteredAuftrage = sortedAuftrage.filter(auftrag => {
        const currentDate = new Date();
        const auftragDate = new Date(auftrag.auftrag_datum);
        const diffTime = auftragDate - currentDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        return diffDays >= -900 && diffDays <= 180;
      });
  
      this.setState({ auftrage: filteredAuftrage });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  

  render() {
    const { auftrage } = this.state;

    return (
      <div className="list-group">
        {auftrage.map((auftrag) => (
          <Fragment key={auftrag.auftrag_nummer}>
            <TerminTR
              showDetails={() => this.showAuftragDetail(auftrag.auftrag_nummer)}
              datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
              thema={auftrag.auftrag_thema}
              auftragsnummer={auftrag.auftrag_id}
              status={auftrag.auftrag_status}
              auftraggeber={auftrag.auftrag_auftraggeber}
            />
          </Fragment>
        ))}
      </div>
    );
  }

  showAuftragDetail(auftragNummer) {
    // Implementiere die Funktionalität zum Anzeigen von Auftragsdetails
    console.log("Auftrag Details anzeigen:", auftragNummer);
  }
}

export default AuftragsListeElementTRAll;
