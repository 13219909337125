import React from 'react';
import DataTable from './DataTable';
import { Button } from 'react-bootstrap';

const DataTabe = () => {
  // Beispiel-Daten
  const data = [
    { Name: 'Mustermann', Vorname: 'Max', Adresse: 'Musterstraße 1', 'E-Mailadresse': 'max@example.com', Telefonnummer: '123456789', Geburtstag: '01.01.1990', Thema: 'ReactJS', 'mögliche Tage': '10', 'freie Tage' : '15', PPP: 'Lorem ipsum', 'Vita (Anhang)': 'vita.pdf', hospitiert: 'Ja' },
    { Name: 'Mustermann', Vorname: 'Arne', Adresse: 'Musterstraße 1', 'E-Mailadresse': 'max@example.com', Telefonnummer: '123456789', Geburtstag: '01.01.1990', Thema: 'ReactJS', 'mögliche Tage': '20', 'freie Tage' : '15',PPP: 'Lorem ipsum', 'Vita (Anhang)': 'vita.pdf', hospitiert: 'Ja' },
    { Name: 'Mustermann', Vorname: 'Sil', Adresse: 'Musterstraße 1', 'E-Mailadresse': 'max@example.com', Telefonnummer: '123456789', Geburtstag: '01.01.1990', Thema: 'ReactJS', 'mögliche Tage': '7', 'freie Tage' : '15', PPP: 'Lorem ipsum', 'Vita (Anhang)': 'vita.pdf', hospitiert: 'Ja' },

];

  return (
    <div>     
         <div className="d-flex justify-content-between align-items-center">

      <h1> / /Trainerdatenbank</h1> 
      <Button variant="danger" className="rounded-circle">+</Button>       <div ></div>
</div>
      <DataTable data={data} />
    </div>
  );
};

export default DataTabe;
