import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";  // Importiere Axios
import RechnungsterminElement from "../rechnungs_element";

function AVallAuftrag() {
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState(" ");
  const [selectedStatus, setSelectedStatus] = useState("zugesagt"); // zugesagt als Standard

  useEffect(() => {
    // Hier wird Axios verwendet, um Daten aus der Datenbank abzurufen
    axios.get("http://34.240.112.160:80/auftrag")  // Passe die URL an
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []); // Das leere Array stellt sicher, dass useEffect nur einmal beim Laden der Komponente ausgeführt wird

  const handleInputChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
    console.log(auftragsView);
  };

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const status = auftrag.auftrag_status.toLowerCase();
  
    if (selectedStatus === "alle") {
      return (
        auftrag.auftrag_thema.toLowerCase().includes(lowerCaseSearchTerm) ||
        auftrag.auftrag_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm)||
        auftrag.auftrag_id.toString().includes(lowerCaseSearchTerm) 
      );
    } else {
      return (
        status === selectedStatus &&
        (auftrag.auftrag_thema.toLowerCase().includes(lowerCaseSearchTerm) ||
          auftrag.auftrag_auftraggeber.toLowerCase().includes(lowerCaseSearchTerm))||
          auftrag.auftrag_id.toString().includes(lowerCaseSearchTerm) 
      );
    }
  });
    return (
      <Fragment>
        <div>
          <div className="col-mb-6">
            <h3> Aufträge nach Status</h3>
          </div>
       
          <div className="input-group mb-3">
            <select id="status" name="status" className="form-select" onChange={handleInputChange} value={selectedStatus}>
              <option value="offen">Offen</option>
              <option value="zugesagt">Zugesagt</option>
              <option value="durchgefuehrt">Durchgeführt</option>
              <option value="abrechnung">Abrechnung</option>
              <option value="historisch">Historisch</option>
            </select>
       
            {/* Suchfeld direkt in der TerminListe */}
            <input
              type="text"
              className="form-control"
              placeholder="Aufträge durchsuchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="list-group">
  {filteredAuftrage.map((auftrag, index) => (
    <Fragment key={index}>
      <RechnungsterminElement
        key={index} // Key auf den Index des Elements setzen
        showDetails={() => showAuftragDetail(auftrag.auftrag_nummer)}
        datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
        thema={auftrag.auftrag_thema}
        auftragsnummer={auftrag.auftrag_id}
        status={auftrag.auftrag_status}
        auftraggeber={auftrag.auftrag_auftraggeber}
        eingang={auftrag.auftrag_eingangsrechnung_zahlung}
        ausgang={auftrag.auftrag_ausgangsrechnung_zahlung}
      />
    </Fragment>
  ))}
</div>


      </Fragment>
    );
  }
  
    
    export default AVallAuftrag;


