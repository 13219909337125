import React, { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Navbar from "../navbar";
import Termin from "../termin";
import Accordion from 'react-bootstrap/Accordion';
import Material from "../material";
import CustomAlert from "../customAlert";
import ProjektAuftrag from "../projekt_auftrag_element";
import Modal from 'react-bootstrap/Modal';
import { Button } from "react-bootstrap";
import FileUpload from "../FileUpload";
import FileElement from "../file-element";
import useAdminCheck from "../rechte_admin";
import NewAnsprechpartnerModal from "./NeuerAnsprechpartner";
import { Tooltip as ReactTooltip } from 'react-tooltip';
import AnsprechpartnerAutoSuggest from "../ansprechpartner_autosuggest";
import RechnungsempfängerAutoSuggest from '../rechnungsempfaenger_autosuggest';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';


function HubProjektCreate() {

  useAdminCheck();
  const { projektnummer } = useParams();
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [projekt, setProjekt] = useState(null);
  const[ap, setAP] =useState(null);
  const [rap,setRAP]=useState(null);
  const[material,setMaterial]= useState([]);
  const [files, setFiles] = useState([]);
  const [buttonColor, setButtonColor] = useState('danger');


// Zustand für den ausgewählten Ansprechpartner
const [selectedAnsprechpartner, setSelectedAnsprechpartner] = useState(null);
const [selectedRechnungsempfänger, setSelectedRechnungsempfänger] = useState(null);

// Callback-Funktion, um den ausgewählten Ansprechpartner zu setzen
const handleSelectedAnsprechpartner = (ansprechpartner) => {
  setSelectedAnsprechpartner(ansprechpartner);
  const selectedAnsprechpartnerId = ansprechpartner ? ansprechpartner.ansprechpartner_id : null;
  setEditableData(prevState => ({
    ...prevState,
    projekt_ansprechpartner: selectedAnsprechpartnerId, // Hier wird der ausgewählte Ansprechpartner gesetzt
  }));
  console.log('Ausgewählte Ansprechpartner Id:', selectedAnsprechpartnerId);
};
const handleRechnungsempfängerSelected = (rechnungsempfänger) => {
    // Setzen Sie den ausgewählten Rechnungsempfänger im Zustand
    setSelectedRechnungsempfänger(rechnungsempfänger);
    const selectedRechnungId = rechnungsempfänger ? rechnungsempfänger.ansprechpartner_id : null;
    setEditableData(prevState => ({
      ...prevState,
      projekt_rechnungsempfaenger: selectedRechnungId, // Hier wird der ausgewählte Ansprechpartner gesetzt
    }));
    console.log('Ausgewählte Rechnung Id:', selectedRechnungId);
  };





  


  //Statusmeldungen für den Ansprechpartner
  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);



  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");


  useEffect(() => {
    // Lade Dateien beim Mounten der Komponente
    axios.get(`http://34.240.112.160:80/projekt-material/${projektnummer}`)
      .then(response => {
        setFiles(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Dateien:", error);
      });
  }, [projektnummer]);


  let editableDataInitialState = {
    projekt_id: "",
    projekt_auftraggeber: "",
    projekt_thema: "",
    projekt_beschreibung: "",
    projekt_umfang: "",
    projekt_tagessatz: "",
    projekt_umsatzsteuer: "",
    projekt_status: "",
    projekt_ma: "",
    ansprechpartner_name: "",
    ansprechpartner_firmenname: "",
    ansprechpartner_mail: "",
    ansprechpartner_ort: "",
    ansprechpartner_plz: "",
    ansprechpartner_strasse: "",
    ansprechpartner_telefon: "",
    r_ansprechpartner_name: "",
    r_ansprechpartner_firmenname: "",
    r_ansprechpartner_mail: "",
    r_ansprechpartner_ort: "",
    r_ansprechpartner_plz: "",
    r_ansprechpartner_strasse: "",
    r_ansprechpartner_telefon: "",
  };

  const [materialList, setMaterialList] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [quantity, setQuantity] = useState("");

  

  const handleAddOrder = () => {
    // Send data to the "mt-bestellen" endpoint
    const orderData = {
      material: selectedMaterial,
      quantity: quantity,
    };

    axios.post(`http://34.240.112.160:80/mt-bestellen/${projektnummer}`, orderData)
      .then(response => {
        console.log("Order successfully placed:", response.data);
        // Close the popup
        setShowModal(false);
      })
      .catch(error => {
        console.error("Error placing order:", error);
      });
  };

  useEffect(() => {
    // Lade Materialien beim Mounten der Komponente
    axios.get("http://34.240.112.160:80/mt-list")
      .then(response => {
        setMaterialList(response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Materialien:", error);
      });
  }, []);



  const [editableData, setEditableData] = useState(editableDataInitialState);
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`http://34.240.112.160:80/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);

        // Hier editableData aktualisieren
        setEditableData(prevData => ({
          ...prevData,
          projekt_id: response.data.projekt_id,
          projekt_auftraggeber: response.data.projekt_auftraggeber,
          projekt_thema: response.data.projekt_thema,
          projekt_beschreibung: response.data.projekt_beschreibung,
          projekt_umfang: response.data.projekt_umfang,
          projekt_tagessatz: response.data.projekt_tagessatz,
          projekt_umsatzsteuer: response.data.projekt_umsatzsteuer,
          projekt_status: response.data.projekt_status,
          projekt_ma: response.data.projekt_ma,
          projekt_km: response.data.projekt_kmtyp,
        }));
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
}, [projektnummer]);

console.log(editableData.projekt_km)
  
  useEffect(() => {
    // Hier sollte die entsprechende Auftrags-ID abgerufen werden (z.B., indem du Axios verwendest)
    axios.get(`http://34.240.112.160:80/projekt/${projektnummer}`)
      .then(response => {
        setProjekt(response.data);
        console.log('Projekt-Daten:', response.data);
      })
      .catch(error => {
        console.error('Error fetching auftrag details:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen von auftragsnummer ausgeführt wird



  useEffect(() => {
    // Daten für die spezifische Projektnummer abrufen
    axios.get(`http://34.240.112.160:80/projekt/${projektnummer}/auftraege`)
      .then(response => {
        setAuftrage(response.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [projektnummer]); // Das Dependency-Array stellt sicher, dass useEffect bei Änderungen der Projektnummer ausgeführt wird

  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_trainer?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });



  useEffect(() => {
    // Lade Projektdaten beim Mounten der Komponente
    axios.get(`http://34.240.112.160:80/mt/${projektnummer}`)
      .then(response => {
        setMaterial(response.data);
        console.log('Material-Daten:', response.data);
      })
      .catch(error => {
        console.error("Fehler beim Abrufen der Projektdaten:", error);
      });
  }, []);


  
  
  if (!projekt) {
    return <div>Loading...</div>;
  }





  
// Event-Handler für Änderungen an den Formularfeldern
const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Ersetze Komma durch Punkt im Wert
  const updatedValue = value.replace(',', '.');

  setEditableData((prevData) => ({
    ...prevData,
    [name]: updatedValue,
  }));
};

  const handleSpeichernClick = () => {
    // Hier kannst du die zu speichernden Daten aus den State-Variablen abrufen

    setAlertType("success");
    setAlertMessage("Erfolgreich gespeichert!");
    setShowAlert(true);

    const { projekt_id, projekt_auftraggeber, projekt_thema, projekt_beschreibung, projekt_umfang, projekt_tagessatz, projekt_umsatzsteuer, projekt_status,projekt_ma,projekt_kmtyp /* andere Daten */ } = projekt;

    // Erstelle ein Objekt mit den zu aktualisierenden Daten
    const updatedData = {
      projekt_id,
      projekt_auftraggeber,
      projekt_thema: editableData.projekt_thema,
      projekt_beschreibung: editableData.projekt_beschreibung,
      projekt_umfang: editableData.projekt_umfang,
      projekt_tagessatz: editableData.projekt_tagessatz,
      projekt_umsatzsteuer: editableData.projekt_umsatzsteuer,
      projekt_status: editableData.projekt_status,
      projekt_ma: editableData.projekt_ma,
      projekt_ansprechpartner: editableData.projekt_ansprechpartner,
      projekt_rechnungsempfaenger: editableData.projekt_rechnungsempfaenger,
      projekt_kmtyp: editableData.km,
      
    };


    console.log('Daten der Aktualisierung:',updatedData );
    // Sende die Anfrage an den Server, um die Daten zu aktualisieren
    axios.put(`http://34.240.112.160:80/projekt/${projektnummer}`, updatedData)
      .then(response => {
        console.log('Daten erfolgreich aktualisiert:', response.data);
        // Optional: Aktualisiere den State, um die Änderungen sofort auf der Oberfläche anzuzeigen
        setProjekt(response.data);

        setAlertType("success");
        setAlertMessage("Erfolgreich gespeichert!");
        setShowAlert(true);
    
      })
      .catch(error => {
        console.error('Fehler beim Aktualisieren der Daten:', error);
      });
  };

  if (Array.isArray(material)) {
    // Hier kannst du die map-Funktion sicher verwenden
    material.map(item => console.log(item));
  } else {
    console.error("material ist kein Array:", material);
  }
  




  return (
    <Fragment>




      <div className="container mt-3">
        <div className="row">
          <div className="col-md-6">
            <h3>{projektnummer}// Neuer Deal Hubspot </h3>
          </div>
          <div className="col-md-6">
          <button type="button" className={`btn btn-${buttonColor} mb-2 w-50`}  onClick={handleSpeichernClick}>
                Speichern
              </button>


              <Link to={`/acreate/${projektnummer}`}>
              <button type="button" className="btn btn-secondary mb-2 w-50">
                Neuer Auftrag
              </button>
              </Link>
              ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
            <input
              type="text"
              className="form-control"
              placeholder="Aufträge durchsuchen..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* Hier kannst du den Inhalt für die Projektansicht hinzufügen */}
            <form className="row g-3">

      <div className="col-md-2">
        <label htmlFor="Projektnummer" className="form-label">No.</label>
        <input type="text" onChange={handleInputChange} className="form-control" id="Projektnummer" value={projekt.projekt_id} disabled />
      </div>

      <div className="col-md-10">
      <label htmlFor="Auftraggeber" className="form-label">Auftraggeber</label>
        <input type="text" className="form-control" name="projekt_auftraggeber" value={projekt.projekt_auftraggeber}   onChange={(e) => {const updatedProjekt = { ...projekt, projekt_auftraggeber: e.target.value };
        setProjekt(updatedProjekt); }}/>
      </div>

      <div className="col-md-6">
        <label htmlFor="Thema" className="form-label">Thema</label>
        <input type="text" onChange={handleInputChange} name="projekt_thema"  className="form-control" id="Thema" value={editableData.projekt_thema} />
      </div>
      <div className="col-md-6">
        <label htmlFor="Beschreibung" className="form-label">Beschreibung</label>
        <input type="text"  className="form-control" name="projekt_beschreibung" value={editableData.projekt_beschreibung} onChange={handleInputChange}/>
      </div>

      <div className="col-md-2">
        <label htmlFor="Umfang" className="form-label">Umfang</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="projekt_umfang" value={editableData.projekt_umfang}/>
      </div>
      <div className="col-md-4">
        <label htmlFor="Tagessatz" className="form-label">Tagessatz</label>
        <input type="text" onChange={handleInputChange} className="form-control" name="projekt_tagessatz" value={editableData.projekt_tagessatz}/>
      </div>
      <div className="col-md-6">
        <label htmlFor="Umsatzsteuer" className="form-label" >Umsatzsteuer</label>
        <select id="projekt_umsatzsteuer" name="projekt_umsatzsteuer" className="form-select"  onChange={handleInputChange} value={editableData.projekt_umsatzsteuer}  >
        <option value="0">Umsatzsteuerfrei</option>
          <option value="1">Umsatzsteuerpflichtig</option>
        </select>
      </div>
      <div className="col-md-12s">
        <label htmlFor="Status" className="form-label">Status</label>
        <select id="projekt_status" name="projekt_status"  className="form-select" onChange={handleInputChange}   value={editableData.projekt_status} >
          <option value="offen">Offen</option>
          <option value="zugesagt">Zugesagt</option>
          <option value="durchgefuehrt">Durchgeführt</option>
          <option value="abrechnung">Abrechnung</option>
          <option value="historisch">Historisch</option>
          <option value="archiviert">Archiviert</option>
          <option value="löschen">Löschen</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="Status" className="form-label">Mitarbeiter</label>
        <select id="inputState" className="form-select"    >
          <option>Peter</option>
          <option>Dennis</option>
        </select>
      </div>
      <div className="col-md-4">
        <label htmlFor="Wegstreckenentschädigung" className="form-label">KM-Abrechnung </label>
        <select id="km" className="form-select" name="km" value={editableData.projekt_km} onChange={handleInputChange}  required  >
        <option value="0.45">0,45 EUR</option>
        <option value="0.30">0,30 EUR</option>

          <option value="1">Pauschal</option>
        </select>      </div>
      <div className="bg-light border"></div>
      <div className="bg-light border"></div>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Fachlicher Ansprechpartner {projekt.projekt_ansprechpartner}</Accordion.Header>
        <Accordion.Body>
        <button  type="button" onClick={handleModalShow} className="btn btn-danger mb-2 w-100">Neuer Ansprechpartner</button>
        <FontAwesomeIcon icon={faInfoCircle} data-tooltip-content="Wirklich nur neue Ansprechpartner anlegen." data-tooltip-id="my-tooltip" data-placement="top" data-bs-toggle="tooltip"  />
        <ReactTooltip id="my-tooltip" place="top" effect="solid"></ReactTooltip>
        <AnsprechpartnerAutoSuggest onAnsprechpartnerSelected={handleSelectedAnsprechpartner} />

      </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Rechnungsempfänger</Accordion.Header>
        <Accordion.Body>
        <button  type="button" onClick={handleModalShow} className="btn btn-danger mb-2 w-100">Neuer Ansprechpartner</button>
        <FontAwesomeIcon icon={faInfoCircle} data-tooltip-content="Wirklich nur neue Ansprechpartner anlegen." data-tooltip-id="my-tooltip" data-placement="top" data-bs-toggle="tooltip"  />
        <ReactTooltip id="my-tooltip" place="top" effect="solid"></ReactTooltip>
      
          <label htmlFor="Ansprechpartner" className="form-label">Rechnungsempfänger</label>
          <RechnungsempfängerAutoSuggest onRechnungsempfängerSelected={handleRechnungsempfängerSelected} />
       
      </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header>Dateien</Accordion.Header>
                <Accordion.Body>
                  
                    <FileUpload projektnummer={projektnummer}/>

        <div className="list-group">
            {files.map(file => (
              <FileElement key={file.files_id} name={file.files_name} link={file.files_url}/>
            ))}
        

      

                 
            </div>  
        </Accordion.Body>
      </Accordion.Item>
      </Accordion>
      </form>
            
          </div>
 
          <div className="col-md-6">
          
            <div className="list-group">
              
              {filteredAuftrage.map((auftrag) => (
                <Fragment key={auftrag.auftrag_nummer}>
                  <ProjektAuftrag
                    datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
                    thema={auftrag.auftrag_thema}
                    auftragsnummer={auftrag.auftrag_id}
                    status={auftrag.auftrag_status}
                    trainer={auftrag.auftrag_trainer}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
 
      
      </div>

      <NewAnsprechpartnerModal show={showModal} handleClose={handleModalClose} />
    </Fragment>
  );
}


export default HubProjektCreate;
