import React, { Fragment, useState, useEffect } from "react";
import Termin from "./termin";
import { Link, Route, BrowserRouter as Router } from "react-router-dom";
import Navbar from "./navbar";
import Test from "./Testumgebung";
import AuftragCheckiste from "./termin_checkliste";
import FileList from "./project_files";
import axios from "axios";  // Importiere Axios
import AuftragSuche from "./auftrag_suchen";

function TerminListe() {
  const [auftragsView, setAuftragsView] = useState(null);
  const [auftrage, setAuftrage] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const trainernummer = 151515;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://34.240.112.160:80/tr/auftrag/${trainernummer}`);
        setAuftrage(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData(); // Funktion ausführen
  }, []);// Das leere Array stellt sicher, dass useEffect nur einmal beim Laden der Komponente ausgeführt wird



  const showAuftragDetail = (auftragsid) => {
    setAuftragsView(auftragsid);
    console.log(auftragsView);
  };


  // Sortiere die auftrage-Liste nach dem auftrag_datum

  const sortedAuftrage = auftrage.sort((a, b) => {
    const dateA = new Date(a.auftrag_datum);
    const dateB = new Date(b.auftrag_datum);
    return dateA - dateB;
  });


  const filteredAuftrage = auftrage.filter(auftrag => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return (
      auftrag.auftrag_thema?.toLowerCase().includes(lowerCaseSearchTerm) ||
      auftrag.auftrag_auftraggeber?.toLowerCase().includes(lowerCaseSearchTerm)
    );
  });

  return (
    <Fragment>
          
            
     <div> <div className="col-mb-6"><h3> Aktuelle Aufträge</h3></div> <div className="col-mb-6">           {/* Suchfeld direkt in der TerminListe */}
          <input
            type="text"
            className="form-control"
            placeholder="Aufträge durchsuchen..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
</div> </div> 
      <div className="list-group">
        {filteredAuftrage.map((auftrag) => (
          <Fragment key={auftrag.auftrag_nummer}>
           
            <Termin
              showDetails={() => showAuftragDetail(auftrag.auftrag_nummer)}
              datum={new Date(auftrag.auftrag_datum).toLocaleDateString('de-DE')}
              thema={auftrag.auftrag_thema}
              auftragsnummer={auftrag.auftrag_id}
              status={auftrag.auftrag_status}
              auftraggeber={auftrag.auftrag_auftraggeber}
            />

          </Fragment>
        ))}
      </div>
       
     
    </Fragment>
  );
}

export default TerminListe;
